import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/ro";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("ro");

export const secondsToDhms = (seconds: number) => {
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " zi, " : " zile, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " ora, " : " ore, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minut, " : " minute, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " secunda" : " secunde") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const getClientTimezone = () => {
  return dayjs.tz.guess();
};
export const areTimezonesDifferent = (one: string, other: string) => {
  return dayjs().tz(one).offsetName() != dayjs().tz(other).offsetName();
};

export default dayjs;

export const dateFormats = {
  ddd: "ddd", // Day of the week (Sun, Mon, etc.)
  dddd: "dddd", // Day of the week (Sunday, Monday, etc.)
  hmmA: "h:mm A",
  DMMM: "D MMM",
  YYYYMMM: "YYYY MMM",
  MMMYYYY: "MMM YYYY",
  DMMMYYYY: "D MMM YYYY",
  DDMMMYYYY: "DD MMM YYYY",
  YYYYMMDD: "YYYY-MM-DD",
  YYYYMMMDD: "YYYY MMM DD",
  DMMMYYYYhmA: "D MMM YYYY h:mm A",
  // Day of the week
};
