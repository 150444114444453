import { ReduxState } from "@/store/types";

import { TrackingGA, TrackingGTM, TrackingState, TrackingUTMParams } from "./types";

const self = (state: ReduxState): TrackingState => state.tracking;
const googleAnalytics = (state: ReduxState): TrackingGA | undefined => state.tracking.config?.googleAnalytics;
const googleTagManager = (state: ReduxState): TrackingGTM | undefined => state.tracking.config?.googleTagManager;
const dynamicScripts = (state: ReduxState): TrackingGA | undefined => state.tracking.config?.dynamicScripts;
const utm_params = (state: ReduxState): TrackingUTMParams | undefined => state.tracking.utm_params;

export const trackingSelectors = {
  self,
  googleAnalytics,
  googleTagManager,
  dynamicScripts,
  utm_params,
};
