import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";

import { accountApi } from "@/core/api/services/account";
import { AUTH_GUEST_COOKIE_KEY, AUTH_USER_COOKIE_KEY } from "@/core/constants";
import { CookieValue } from "@/core/utils/cookies";
import { uuidv4 } from "@/core/utils/string";

import { isClient } from "@/core/utils/nextjs";
import { authActions, InitializePayload } from "./actions";
import { authSelectors } from "./selectors";
import { AuthStatus, Guest, Token, TokenType, User, UserPreference, UserSecurity } from "./types";
import { callRTKQuery, resetApiState } from "../api/sagas";
import { contextActions } from "../context/actions";
import { contextSelectors } from "../context/selectors";
import { appActions } from "../app/actions";
import { AppStatus } from "../app/types";

function* onInitialize(action: PayloadAction<InitializePayload>): Generator {
  const { token } = action.payload;
  if (token) {
    yield onInitializeFromToken(token);
  } else {
    yield onInitializeFromCookies();
  }
}

function* onInitializeFromToken(token: Token): Generator {
  if (token.type == TokenType.USER) {
    yield resumeUserToken(token);
    yield put(contextActions.setCookie({ key: AUTH_USER_COOKIE_KEY, value: token.token }));
  } else {
    yield resumeGuest(token);
    yield put(contextActions.setCookie({ key: AUTH_GUEST_COOKIE_KEY, value: token.token }));
  }
  yield put(authActions.setStatus(AuthStatus.READY));
}

function* onInitializeFromCookies(): Generator {
  // Try to init user
  const user_token = (yield select(contextSelectors.requestCookieValue, AUTH_USER_COOKIE_KEY)) as CookieValue;
  if (user_token) {
    try {
      const token = {
        token: user_token.toString(),
        type: TokenType.USER,
      };
      yield onInitializeFromToken(token);
      yield put(authActions.setStatus(AuthStatus.READY));
      return; // Stop here
    } catch (e) {
      console.error("Error while resuming user token");
      yield put(contextActions.deleteCookie(AUTH_USER_COOKIE_KEY));
    }
  }

  // If we got to this point, we don't have a user
  let guest_token = (yield select(contextSelectors.requestCookieValue, AUTH_GUEST_COOKIE_KEY)) as string;
  if (!guest_token) {
    guest_token = uuidv4();
    yield put(contextActions.setCookie({ key: AUTH_GUEST_COOKIE_KEY, value: guest_token }));
  }
  const token = {
    token: guest_token,
    type: TokenType.GUEST,
  };
  yield onInitializeFromToken(token);
}

function* resumeUserToken(token: Token): Generator {
  const data: any = yield callRTKQuery(
    accountApi.endpoints.checkTokenAndGetSummary.initiate({
      token: token.token,
    }),
  );
  yield put(authActions.setToken(token));
  yield put(authActions.setUser(data.customer as User));
  yield put(authActions.setUserSecurity(data.security as UserSecurity));
  yield put(authActions.setUserPreference(data.preference as UserPreference));
}

function* onRefresh(): Generator {
  const isUser = (yield select(authSelectors.isUser)) as boolean;
  if (!isUser) return;

  const data: any = yield callRTKQuery(accountApi.endpoints.getSummary.initiate());
  yield put(authActions.setUser(data.customer as User));
  yield put(authActions.setUserSecurity(data.security as UserSecurity));
  yield put(authActions.setUserPreference(data.preference as UserPreference));
}

function* resumeGuest(token: Token): Generator {
  const guest: Guest = {
    id: token.token,
    name: "Guest",
  };
  yield put(authActions.setToken(token));
  yield put(authActions.setGuest(guest));
}

function* onLogout(): Generator {
  yield put(contextActions.deleteCookie(AUTH_USER_COOKIE_KEY));
  yield put(contextActions.deleteCookie(AUTH_GUEST_COOKIE_KEY));
  yield put(authActions.reset());
  yield put(authActions.setStatus(AuthStatus.READY));
  yield resetApiState();
}

function* onAppSetStatus(action: PayloadAction<AppStatus>): Generator {
  if (!isClient()) return;
  if (action.payload === AppStatus.READY_FULL) {
    // check if we need to refresh the token
    const isUser = (yield select(authSelectors.isUser)) as boolean;
    if (!isUser) return;
    const data: any = yield callRTKQuery(accountApi.endpoints.refreshTokenIfNeeded.initiate());
    if (data.new_token) {
      const new_token: Token = {
        type: TokenType.USER,
        token: data.new_token,
      };
      yield put(authActions.setToken(new_token));
      yield put(
        contextActions.setCookie({
          key: AUTH_USER_COOKIE_KEY,
          value: data.new_token,
        }),
      );
    }
  }
}

export default function* sagas(): Generator {
  yield takeLatest(authActions.initialize, onInitialize);
  yield takeLatest(authActions.refresh, onRefresh);
  yield takeLatest(authActions.logout, onLogout);
  yield takeLatest(appActions.setStatus, onAppSetStatus);
}
