import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";

import { isClient } from "@/core/utils/nextjs";
import { trackingActions } from "./actions";
import { TrackingConfig, TrackingUTMParams } from "./types";
import { trackingSelectors } from "./selectors";
import { siteActions } from "../site/actions";
import { SiteConfig } from "../site/types";
import { AppStatus } from "../app/types";
import { authSelectors } from "../auth/selectors";
import { User } from "../auth/types";
import { appActions } from "../app/actions";
import { authActions } from "../auth/actions";
import { routerActions } from "../router/actions";

function* onSiteSetCodeWithConfig(action: PayloadAction<{ config: SiteConfig }>): Generator {
  const tracking = action.payload.config?.tracking;
  if (tracking) {
    yield put(trackingActions.setConfig(tracking as TrackingConfig));
  }
}

function* onAppSetStatus(action: PayloadAction<AppStatus>): Generator {
  if (!isClient()) return;
  if (action.payload === AppStatus.READY_FULL) {
    yield onSetOrChangeUrl();
    const user = (yield select(authSelectors.user)) as User | undefined;
    if (user?.id) {
      Sentry.setUser({ provider_id: user.id, type: "provider", name: user.name });
    }
    const scripts = (yield select(trackingSelectors.dynamicScripts)) as string[] | undefined;
    if (scripts?.length) {
      yield injectDynamicScripts(scripts);
    }
  }
}

function* onSetOrChangeUrl(): Generator {
  if (!isClient()) return;
  try {
    const existingUtmParams = (yield select(trackingSelectors.utm_params)) as TrackingUTMParams | undefined;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("utm_source") && urlParams.get("utm_medium") && urlParams.get("utm_campaign")) {
      const utmParams: TrackingUTMParams = {
        utm_source: urlParams.get("utm_source") || undefined,
        utm_medium: urlParams.get("utm_medium") || undefined,
        utm_campaign: urlParams.get("utm_campaign") || undefined,
      };
      if (
        existingUtmParams?.utm_campaign !== utmParams.utm_campaign ||
        existingUtmParams?.utm_medium !== utmParams.utm_medium ||
        existingUtmParams?.utm_source !== utmParams.utm_source
      ) {
        yield put(trackingActions.setUTMParams(utmParams));
      }
    }
  } catch (error) {
    console.error("Failed to get URL params", error);
  }
}

function* injectDynamicScripts(scripts: string[]): Generator {
  if (!isClient()) return;

  try {
    // Jnject dynamic scripts at the end of the body and make sure that they are found only once
    const body = document.querySelector("body");
    if (!body) return;
    const existingScripts = body.querySelectorAll("script[data-dynamic]");
    existingScripts.forEach((script) => script.remove());
    for (const src of scripts) {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.dataset.dynamic = "true";
      body.appendChild(script);
    }
  } catch (error) {
    console.error("Failed to inject dynamic tags", error);
  }
}
function* onAuthSetUser(action: PayloadAction<User>): Generator {
  if (!isClient()) return;
  const user = action.payload;
  if (user?.id) {
    Sentry.setUser({ provider_id: user.id, type: "provider", name: user.name });
  }
}

function* onAuthLogout(action: PayloadAction): Generator {
  if (!isClient()) return;
  Sentry.setUser(null);
}

function* onUTMParamsSet(action: PayloadAction<TrackingUTMParams>): Generator {
  if (!isClient()) return;
  const utmParams = action.payload;
  if (utmParams) {
  }
}

export default function* sagas(): Generator {
  yield takeLatest(siteActions.setCodeWithConfig, onSiteSetCodeWithConfig);
  yield takeLatest(appActions.setStatus, onAppSetStatus);
  yield takeLatest(authActions.setUser, onAuthSetUser);
  yield takeLatest(authActions.logout, onAuthLogout);
  yield takeLatest(routerActions.setUrl, onSetOrChangeUrl);
  yield takeLatest(trackingActions.setUTMParams, onUTMParamsSet);
}
