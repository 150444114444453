import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const salesApi = createApi({
  reducerPath: "salesApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["Transactions", "BusinessInfo", "PaymentSettings", "Reports", "ReferralInfo"],
  endpoints: (builder) => ({
    getTransactions: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-sales/providers/transactions`, queryParams),
      providesTags: ["Transactions"],
    }),
    getBusinessInfo: builder.query<any, void>({
      query: () => `/api/providers-sales/providers/business-info`,
      providesTags: ["BusinessInfo"],
    }),
    updateBusinessInfo: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-sales/providers/business-info`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BusinessInfo"],
    }),
    getPaymentSettings: builder.query<any, void>({
      query: () => `/api/providers-sales/providers/payment-info`,
      providesTags: ["PaymentSettings"],
    }),
    createStripeOnboardingUrlSettings: builder.mutation<any, void>({
      query: () => ({
        url: `/api/providers-sales/providers/payment-info/stripe-url`,
        method: "POST",
      }),
      invalidatesTags: ["PaymentSettings"],
    }),
    getReferralInfo: builder.query<any, void>({
      query: () => ({
        url: `/api/providers-sales/providers/referrals/info`,
      }),
      providesTags: ["ReferralInfo"],
    }),
    getSalesReports: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-sales/providers/reports`, queryParams),
      providesTags: ["Reports"],
    }),
    getSalesReportFile: builder.query<any, string>({
      query: (id) => `/api/providers-sales/providers/reports/${id}/download`,
    }),
  }),
});
export const {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
  useGetBusinessInfoQuery,
  useUpdateBusinessInfoMutation,
  useGetPaymentSettingsQuery,
  useCreateStripeOnboardingUrlSettingsMutation,
  useGetSalesReportsQuery,
  useLazyGetSalesReportsQuery,
  useGetSalesReportFileQuery,
  useGetReferralInfoQuery,
} = salesApi;
