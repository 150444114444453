const PHONE_NUMBER_REGEX = /^[0-9]*$/;

export type ValidationResult = string | undefined;

export type Validator = (value: string) => ValidationResult;

export const isRequired: Validator = (value: string): ValidationResult => {
  if (!value) return "Obligatoriu";
};

export const isEmail: Validator = (value: string): ValidationResult => {
  if (!value) return "Obligatoriu";
};

export const isPassword: Validator = (value: string): ValidationResult => {
  if (!value) return "Obligatoriu";
};

export const multipleOf =
  (multiple: number): Validator =>
  (value: string): ValidationResult => {
    if (value && parseInt(value) % multiple !== 0) {
      return `Trebuie să fie multiplu de ${multiple}`;
    }
  };

export const hasLength =
  (min?: number, max?: number): Validator =>
  (value: string): ValidationResult => {
    if (min && (!value || value.length < min)) {
      return `Minim ${min} caractere`;
    }
    if (max && value && value.length > max) {
      return `Maxim ${max} caractere`;
    }
  };

export const combined =
  (...validators: Array<Validator>): Validator =>
  (value: string): ValidationResult => {
    for (const validator of validators) {
      const result = validator(value);
      if (result) return result;
    }
  };

export const isNumeric = (value: string) =>
  PHONE_NUMBER_REGEX.test(value) ? undefined : "Trebuie să conțină doar cifre";
