import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseApiQuery(),
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/auth/login`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/auth/register`,
        method: "POST",
        body: data,
      }),
    }),
    oauthInit: builder.mutation({
      query: ({ provider }) => ({
        url: `/api/providers/auth/oauth-init`,
        method: "POST",
        body: { provider },
      }),
    }),
    oauthHandle: builder.mutation({
      query: (data) => ({
        url: `/api/providers/auth/oauth-handle`,
        method: "POST",
        body: data,
      }),
    }),
    forgotPasswordInit: builder.mutation({
      query: (data) => ({
        url: `/api/providers/auth/forgot-password`,
        method: "POST",
        body: data,
      }),
    }),
    forgotPasswordHandle: builder.mutation({
      query: (data) => ({
        url: `/api/providers/auth/change-password`,
        method: "POST",
        body: data,
      }),
    }),
    confirmEmailResend: builder.mutation<any, void>({
      query: () => ({
        url: `/api/providers/auth/confirm-email-resend`,
        method: "POST",
      }),
    }),
    confirmEmail: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/auth/confirm-email`,
        method: "POST",
        body: data,
      }),
    }),
    confirmEmailChange: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/auth/confirm-email-change`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useLoginMutation,
  useRegisterMutation,
  useOauthInitMutation,
  useOauthHandleMutation,
  useForgotPasswordInitMutation,
  useForgotPasswordHandleMutation,
  useConfirmEmailResendMutation,
  useConfirmEmailMutation,
  useConfirmEmailChangeMutation,
} = authApi;
